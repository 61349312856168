import { startCase } from 'lodash-es';

// Fn to fetch notification content
export const getNotificationData = (selectedReport: string, error = '') => {
  const reportType = startCase(selectedReport);
  const hasReportWord = reportType.includes('Report');
  const isBaseReport = reportType.includes('Base');
  const notificationObj = {
    severity: 'success',
    summary: `${reportType}${hasReportWord ? '' : ' report'} is being generated in background`,
    detail: isBaseReport ? 'File will be downloaded once report is ready' : `You'll receive a notification once report is ready`,
    closable: true
  };
  if (error) {
    notificationObj.severity = 'error';
    notificationObj.summary = `Failed to generate ${reportType}${hasReportWord ? '' : ' report'}`;
    notificationObj.detail = `${error}. Please try again later`;
  }

  return notificationObj;
};

// Fn to fetch error string from error instance
export const fetchErrorMsg = (err: any) => {
  const msg = err?.error?.message;
  if (typeof msg !== 'string' && Array.isArray(msg)) {
    return msg.join('; ');
  }
  return msg || 'Something went wrong';
};
